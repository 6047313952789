import * as THREE from 'three';
// import { OrbitControls } from './js/OrbitControls.js';
import { GLTFLoader } from './js/GLTFLoader.js';
import { DRACOLoader } from './js/DRACOLoader.js';
import {Text} from 'troika-three-text'
let camera, scene, renderer, mixer, gltfModel, controls;
const clock = new THREE.Clock();
init();

function init() {

    const container = document.createElement('div');
    document.body.appendChild(container);

    camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.25, 20);
    camera.position.set(- 1.8, 0.6, 2.7);

    scene = new THREE.Scene();

    const loader = new GLTFLoader();
    // Optional: Provide a DRACOLoader instance to decode compressed mesh data
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('https://storage.delysium.com/draco/');
    loader.setDRACOLoader(dracoLoader);
    // Create:

    loader.load('model/fishtank.glb', function (gltf) {
        gltfModel = gltf;
        scene.add(gltf.scene);
        gltf.animations; // Array<THREE.AnimationClip>
        gltf.scene; // THREE.Group
        gltf.scenes; // Array<THREE.Group>
        gltf.cameras; // Array<THREE.Camera>
        gltf.asset; // Object
        mixer = new THREE.AnimationMixer(gltf.scene);
        gltf.animations.forEach((clip) => {

            mixer.clipAction(clip).play();

        });

        const myText = new Text()
        scene.add(myText)

        // Set properties to configure:
        myText.text = 'Pitchdeck text goes here'
        myText.fontSize = 0.1
        myText.position.x = -0.75
        myText.position.y =0.5
        myText.position.z = -32.4
        myText.color = 0x9966FF
        myText.position.scale=0.5
        // Update the rendering:
        myText.sync()


        camera.position.x = 0;
        camera.position.y = 0;
        camera.position.z = -30;

        animate();

    });
    renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.toneMappingExposure = 1;
    renderer.outputEncoding = THREE.sRGBEncoding;
    container.appendChild(renderer.domElement);

    // controls = new OrbitControls(camera, renderer.domElement);
    // //controls.addEventListener( 'change', render ); // use if there is no animation loop
    // controls.minDistance = 2;
    // controls.maxDistance = 10;
    // controls.target.set(0, 0, - 24);
    // controls.update();



    window.addEventListener('resize', onWindowResize);

}

function onWindowResize() {

    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    renderer.setSize(window.innerWidth, window.innerHeight);
}
const plus = true;
function animate() {
    if (mixer) {

        requestAnimationFrame(animate);
        const delta = clock.getDelta();
        mixer.update(delta);
        //controls.update();
        renderer.render(scene, camera);
    }
};

animate();

// Control
var mouseTolerance = 0.0001;

window.addEventListener('mousemove', function (e) {
    var centerX = window.innerWidth * 0.5;
    var centerY = window.innerHeight * 0.5;

    camera.position.x = (e.clientX - centerX) * mouseTolerance;
    camera.position.y = -(e.clientY - centerY) * mouseTolerance;
});